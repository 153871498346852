<template>
  <v-container>
    <v-row>
      <v-breadcrumbs large :items="items_nav">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-card class="ma-4">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
        <download-csv
            class="mx-2"
            :name="nameTable"
            :labels="labelsTable"
            :fields="fieldsTable"
            :data="dataTable">
            <v-icon>mdi-file-delimited</v-icon>
        </download-csv>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="dataTable"
        :search="search"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:item.onde_conheceu="{ item }">
            {{ item.onde_conheceu | without }}
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getDataFromApi">Reiniciar</v-btn>
        </template>
      </v-data-table>

     

      <v-dialog v-model="dialogProgress" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Realizando operação por favor aguarde...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import database from "@/services/database";
import moment from "moment";

export default {
  name: "Usuarios",
  data() {
    return {
      items_nav: [
        {
          text: "Sistema",
          disabled: false,
        },
        {
          text: "Usuários",
          disabled: true,
        },
        {
          text: "Listagem",
          disabled: true,
        }
      ],
      loading: true,
      search: "",
      singleSelect: false,
      selected: [],
      itemSelected: null,
      dialog: false,
      dialogProgress: false,
      dialogEdit: false,
      dialogAddress: false,
      address: [],
      registers: [],
      headers: [
        { text: "Email", value: "email" },
        { text: "Nome", value: "nome" },
        { text: "Onde Conheceu", value: "onde_conheceu" },
        { text: "UF", value: "uf" }
      ],
      nameTable: "",
      labelsTable:{ data_criacao: 'Data de Cadastro',  nome: 'Nome' , email: 'Email' , uf: 'UF', onde_conheceu: 'Onde Conheceu' ,  com_facebook:  'Facebook?' ,  photo_url: 'Foto URL' }, 
      fieldsTable:["data_criacao", "nome", "email", "uf", "onde_conheceu", "com_facebook", "photo_url"], 
      dataTable: [],
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi: async function() {
      this.loading = true;
      this.nameTable = "Relatório de Usuários ".concat(new Date().toLocaleDateString()).concat(".csv")
      var data = await database.getAllUsuarios();
      await data.forEach((element, index)  => {
        element.politica_aceita_em = this.convertTimestamp(element.politica_aceita_em)
        element.data_criacao = this.convertTimestamp(element.data_criacao)
        element["com_facebook"] = element.is_facebook ? "SIM" : "NÃO"
        console.log(element.com_facebook)
        if (element.onde_conheceu == "") {
          element.onde_conheceu = "Não Informado"
        }
        delete element.tipo
        delete element.id
        delete element.fornecedores_favoritos
        delete element.is_facebook
        delete element.fornecedor_id
        delete element.data_atualizacao
        delete element.politica_aceita_em
      });
      this.dataTable = data;
      this.loading = false;
    },
    convertTimestamp: function(value) {      
      var data = value.toDate();
      return moment(data).format("DD/MM/YYYY HH:mm:ss");
    }
  },
};
</script>
